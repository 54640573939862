<!--
 * @Description: 店铺信息
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:34:20
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-01-28 16:44:28
-->
<template>
  <el-card class="box">
    <el-tabs>
      <el-tab-pane label="基本信息">
        <div>
          <upload-avatar
            :url="model.logo_pic"
            @change="logoChange"
          ></upload-avatar>
          <div class="help-block">
            建议宽高使用400x400，比例1：1图片，支持png、jpeg
          </div>
        </div>
        <el-descriptions style="margin-top: 20px">
          <el-descriptions-item label="商户名称"
            ><b>{{ model.name }}</b></el-descriptions-item
          >
          <el-descriptions-item label="联系人">{{
            model.contact
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{
            model.mobile_phone
          }}</el-descriptions-item>
          <el-descriptions-item label="等级"
            >{{ model.level + 1 }}级</el-descriptions-item
          >
          <el-descriptions-item label="状态">
            <el-tag type="success" size="small">{{ model.status_text }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            model.create_date
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="店铺名称">{{
            model.name
          }}</el-descriptions-item> -->
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="结算设置">
        <el-form
          :model="settle_form"
          ref="settle_form"
          :rules="settle_rules"
          label-width="140px"
        >
          <el-form-item label="结算方式" prop="settle_type">
            <!-- <el-select
              v-model="settle_form.settle_type"
              placeholder=""
              v-if="is_edit_settle"
            >
              <el-option label="日结" :value="0"></el-option>
              <el-option label="周结" :value="5"></el-option>
              <el-option label="月结" :value="10"></el-option>
            </el-select> -->
            <span>{{ model.settle_type_text }}</span>
          </el-form-item>

          <el-form-item label="有效开始日期" prop="settle_start_date">
            <!-- <el-date-picker
              v-model="settle_form.settle_start_date"
              value-format="YYYY-MM-DD"
              format="YYYY-MM-DD"
              placeholder
            ></el-date-picker> -->
            <span>{{ model.settle_start_date }}</span>
          </el-form-item>
          <el-form-item>
            <!-- <el-button
              type="primary"
              @click="onSettleSubmit"
              v-if="is_edit_settle"
              >保存</el-button
            >
            <el-button @click="is_edit_settle = false" v-if="is_edit_settle"
              >取消</el-button
            > -->
            <el-button type="primary" link @click="dialogVisible = true"
              >修改</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </el-card>
  <el-dialog
    title="修改结算方式"
    v-model="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div></div>
    <template #footer>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import UploadAvatar from "../upload/upload_avatar.vue";
export default {
  components: {
    UploadAvatar,
  },
  data() {
    return {
      dialogVisible: false,
      model: {},
      is_edit_settle: false,
      settle_form: {},
      settle_rules: {
        settle_type: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      settle_start_date: {
        settle_type: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * logo图片上传更改
     * @param {*} url
     */
    logoChange(url) {
      this.$http.post("seller/v1/business/update/logo", {
        url: url,
      });
    },

    /**
     *
     */
    loadData() {
      this.$http.get("seller/v1/business").then((res) => {
        if (res.code == 0) {
          this.model = res.data;
          this.settle_form = Object.assign({}, res.data);
        }
      });
    },

    /**
     *
     */
    onSubmit() {},

    /**
     *
     */
    onSettleSubmit() {},
  },
};
</script>

<style   scoped>
</style>